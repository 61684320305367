import VacanciesStaticPage from './VacanciesStaticPage';
import { baseRoutes } from '../../routeDefinitions';
import { getRouterPath } from '../../helpers/path';

const staticPages = (i18n, locale) => ([
    {
        key: 'terms',
        path: getRouterPath(i18n, baseRoutes.STATIC.TERMS, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Algemene voorwaarden',
            de: 'Allgemeine Geschäftsbedingungen',
        },
        content: {
            nl: () => import('./pageContent/terms-nl.md?raw'),
            de: () => import('./pageContent/terms-de.md?raw'),
        },
    },
    {
        key: 'cookie',
        path: getRouterPath(i18n, baseRoutes.STATIC.COOKIES, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Cookieverklaring',
            en: 'Cookie Statement',
        },
        content: {
            nl: () => import('./pageContent/cookie-nl.md?raw'),
            en: () => import('./pageContent/cookie-en.md?raw'),
        },
        linkList: ['cookie', 'privacy'],
    },
    {
        key: 'cookie-de',

        defaultLocale: 'de',
        title: {
            de: 'Cookie-Erklärung',
        },
        content: {
            de: () => import('./pageContent/cookie-de.md?raw'),
        },
        linkList: ['cookie-de', 'privacy-de'],
    },
    {
        key: 'privacy',
        path: getRouterPath(i18n, baseRoutes.STATIC.PRIVACY, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Privacyverklaring',
            en: 'Privacy Statement',
        },
        content: {
            nl: () => import('./pageContent/privacy-nl.md?raw'),
            en: () => import('./pageContent/privacy-en.md?raw'),
        },
        linkList: ['cookie', 'privacy'],
    },
    {
        key: 'privacy-de',

        defaultLocale: 'de',
        title: {
            de: 'Datenschutzerklärung',
        },
        content: {
            de: () => import('./pageContent/privacy-de.md?raw'),
        },
        linkList: ['cookie-de', 'privacy-de'],
    },
    {
        key: 'about',
        path: getRouterPath(i18n, baseRoutes.STATIC.ABOUT, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Meet Athena!',
            en: 'Meet Athena!',
            de: 'Lerne AthenaStudies kennen!',
        },
        description: {
            nl: 'AthenaStudies is een organisatie voor studenten, door topstudenten met een sterke focus op kwaliteit.',
            en: 'AthenaStudies is an organization for and led by students with a strong focus on quality.',
            de: 'AthenaStudies ist eine Organisation für Studenten und geführt von Studenten, mit einem starken Fokus auf Qualität.',
        },
        content: {
            nl: () => import('./pageContent/about-nl.md?raw'),
            en: () => import('./pageContent/about-en.md?raw'),
            de: () => import('./pageContent/about-de.md?raw'),
        },
    },
    {
        key: 'vacancies-teacher',
        path: getRouterPath(i18n, baseRoutes.STATIC.VACANCIES_TEACHER, locale, true),
        parentPage: VacanciesStaticPage,

        defaultLocale: 'nl',
        title: {
            nl: 'Docent bij AthenaStudies',
            en: 'Tutor at AthenaStudies',
            de: 'Tutor bei AthenaStudies',
        },
        content: {
            nl: () => import('./pageContent/vacancies-teacher-nl.md?raw'),
            en: () => import('./pageContent/vacancies-teacher-en.md?raw'),
            de: () => import('./pageContent/vacancies-teacher-de.md?raw'),
        },
    },
    {
        key: 'vacancies-en-teacher',
        parentPage: VacanciesStaticPage,

        defaultLocale: 'en',
        title: {
            en: 'Tutor at AthenaStudies',
        },
        content: {
            en: () => import('./pageContent/vacancies-en-teacher-en.md?raw'),
        },
    },
    {
        key: 'vacancies-de-teacher',
        companyAbbr: ['ASTU-DE'],
        parentPage: VacanciesStaticPage,

        defaultLocale: 'de',
        title: {
            en: 'Tutor at AthenaStudies',
            de: 'Tutor bei AthenaStudies',
        },
        content: {
            en: () => import('./pageContent/vacancies-de-teacher-en.md?raw'),
            de: () => import('./pageContent/vacancies-de-teacher-de.md?raw'),
        },
    },
    {
        key: 'vacancies-check-editor',
        path: 'vacatures/athenacheck',

        defaultLocale: 'nl',
        title: {
            nl: 'Redacteur bij AthenaCheck',
        },
        content: {
            nl: () => import('./pageContent/vacancies-check-editor-nl.md?raw'),
        },
    },
    {
        key: 'school-about',
        path: getRouterPath(i18n, baseRoutes.STATIC.SCHOOL_ABOUT, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Over ons',
        },
        content: {
            nl: () => import('./pageContent/school-about.md?raw'),
        },
    },
    {
        key: 'school-exam',
        path: getRouterPath(i18n, baseRoutes.STATIC.SCHOOL_EXAM, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Onze examentraining',
        },
        content: {
            nl: () => import('./pageContent/school-exam.md?raw'),
        },
    },
    {
        key: 'school-bijles',
        path: getRouterPath(i18n, baseRoutes.STATIC.SCHOOL_BIJLES, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Bijles',
        },
        content: {
            nl: () => import('./pageContent/school-bijles.md?raw'),
        },
    },
    {
        key: 'school-huiswerkbegeleiding',
        path: getRouterPath(i18n, baseRoutes.STATIC.SCHOOL_HUISWERKBEGELEIDING, locale, true),

        defaultLocale: 'nl',
        title: {
            nl: 'Huiswerkbegeleiding',
        },
        content: {
            nl: () => import('./pageContent/school-huiswerkbegeleiding.md?raw'),
        },
    },

    // Pages for AthenaSchool 30 May 2022
    {
        key: 'school-nationaal-programma-onderwijs',
        path: 'nationaal-programma-onderwijs',

        defaultLocale: 'nl',
        title: 'Nationaal Programma Onderwijs: maatwerk voor uw school',
        titleMeta: 'Nationaal Programma Onderwijs: maatwerk van AthenaSchool',
        description: 'Hoe maakt u goed gebruik van het Nationaal Programma Onderwijs? AthenaSchool biedt maatwerk-interventies voor uw school. Van bijles tot klassenassistenten.',
        content: () => import('./pageContent/school/nationaal-programma-onderwijs.md?raw'),
    },
    {
        key: 'basisvaardigheden',
        path: 'basisvaardigheden',

        defaultLocale: 'nl',
        title: 'Basisvaardigheden',
        titleMeta: 'Programma basisvaardigheden',
        description: 'Wij zien vaak dat leerlingen met een te laag lees- en rekenniveau de basisschool verlaten, waardoor ze in de onderbouw een blijvende achterstand opbouwen. Daarom bieden we ons basisvaardighedenprogramma aan. In workshops en oefensessies leren onze trainers leerlingen altijd werkende procedures aan voor belangrijke vaardigheden. We vinden individuele aandacht belangrijk, daarom werken we in kleine groepen (ong. 10 leerlingen) en houden we de prestaties van iedere leerling bij in ons leerlingvolgsysteem.',
        content: () => import('./pageContent/school/basisvaardigheden.md?raw'),
    },
    {
        key: 'school-lente-en-zomerschool',
        path: 'lente-en-zomerschool',

        defaultLocale: 'nl',
        title: 'Lente- en zomerschool',
        titleMeta: 'Lente- en zomerschool organiseren? AthenaSchool helpt!',
        description: 'Wilt u leerlingen met achterstanden helpen? AthenaSchool ontwikkelt een lente- of zomerschool op maat. Maatwerk voor een betaalbare prijs! Bekijk de opties.',
        content: () => import('./pageContent/school/lente-en-zomerschool.md?raw'),
    },
    {
        key: 'examentraining',
        path: 'examentraining',

        defaultLocale: 'nl',
        title: 'Examentraining vmbo, havo en vwo',
        titleMeta: 'Examentrainingen vwo, havo en vmbo, op maat voor uw school',
        description: 'Verbeter de slagingspercentages, bied een examentraining aan. AthenaSchool stelt een examentraining op maat voor u samen. Bekijk de opties.',
        content: () => import('./pageContent/school/examentraining.md?raw'),
    },
    {
        key: 'klassenassistent-inschakelen',
        path: 'klassenassistent-inschakelen',

        defaultLocale: 'nl',
        title: 'Klassenassistent(en) inschakelen voor uw sectie(s)?',
        titleMeta: 'Klassenassistenten inschakelen? AthenaSchool helpt!',
        description: 'Wilt u de werkdruk voor docenten verlagen en achterstanden bij leerlingen verkleinen? Schakel klassenassistenten van AthenaSchool in. Lees over de voordelen.',
        content: () => import('./pageContent/school/klassenassistent-inschakelen.md?raw'),
    },
    {
        key: 'lesopvang-invalkracht',
        path: 'lesopvang-invalkracht',

        defaultLocale: 'nl',
        title: 'Lesopvang of vaste invalkracht inschakelen',
        titleMeta: 'Lesopvang of vaste invalkracht regelen? AthenaSchool helpt!',
        description: 'Wilt u een openstaande vacature vervullen? Of heeft u behoefte aan een vaste invalkracht? AthenaSchool biedt dé flexibele oplossing. Lees hier meer.',
        content: () => import('./pageContent/school/lesopvang-invalkracht.md?raw'),
    },
    {
        key: 'cursus-leren-leren',
        path: 'cursus-leren-leren',

        defaultLocale: 'nl',
        title: 'Cursus leren leren voor uw leerlingen',
        titleMeta: 'Cursus leren leren op maat? Bekijk onze opties',
        description: 'Wilt u de leervaardigheden van uw leerlingen verbeteren en hun zelfvertrouwen vergroten? AthenaSchool biedt een cursus leren leren op maat. Lees hier meer!',
        content: () => import('./pageContent/school/cursus-leren-leren.md?raw'),
    },
    {
        key: 'huiswerkklas-huiswerkbegeleiding',
        path: 'huiswerkklas-huiswerkbegeleiding',

        defaultLocale: 'nl',
        title: 'Huiswerkklas: persoonlijke huiswerkbegeleiding op school',
        titleMeta: 'Besteed uw huiswerkklas uit aan AthenaSchool',
        description: 'Ontlast uw docenten en laat de huiswerkklas over aan AthenaSchool. Wij bieden leerlingen een plek om te werken aan vakken én aan hun leervaardigheid.',
        content: () => import('./pageContent/school/huiswerkklas-huiswerkbegeleiding.md?raw'),
    },
    {
        key: 'onderwijssurveillant-examens',
        path: 'onderwijssurveillant-examens',

        defaultLocale: 'nl',
        title: 'Schakel een onderwijssurveillant in om uw docenten te ontlasten',
        titleMeta: 'Besteed uw surveillance uit aan onze surveillanten onderwijs!',
        description: 'Ontlast uw docenten, laat het surveilleren over aan AthenaSchool. Onze getrainde surveillanten zijn flexibel inzetbaar en leiden toetsen in goede banen.',
        content: () => import('./pageContent/school/onderwijssurveillant-examens.md?raw'),
    },

    // AtotZ
    {
        key: 'atotz-about',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: {
            nl: 'Over ons',
        },
        content: {
            nl: () => import('./pageContent/atotz/atotz-about.md?raw'),
        },
    },
    {
        key: 'vestigingen',
        path: 'vestigingen',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: 'Vestigingen',
        titleMeta: 'Vestigingen van AtotZ Huiswerkbeleiding',
        description: 'Vestigingen van AtotZ Huiswerkbeleiding',
        content: () => import('./pageContent/atotz/vestigingen.md?raw'),
    },
    {
        key: 'tarieven',
        path: 'tarieven',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: 'Tarieven',
        titleMeta: 'Tarieven van AtotZ Huiswerkbeleiding',
        description: 'Tarieven van AtotZ Huiswerkbeleiding',
        content: () => import('./pageContent/atotz/tarieven.md?raw'),
    },
    {
        key: 'atotz-contact',
        path: 'atotz-contact',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: 'Contact',
        titleMeta: 'Contact',
        description: 'Contact AtotZ Huiswerkbeleiding',
        content: () => import('./pageContent/atotz/contact.md?raw'),
    },
    {
        key: 'werken-bij',
        path: 'werken-bij',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: 'Werken bij + vacatures',
        titleMeta: 'Werken bij + vacatures AtotZ Huiswerkbeleiding',
        description: 'Werken bij + vacatures AtotZ Huiswerkbeleiding',
        content: () => import('./pageContent/atotz/vacatures.md?raw'),
    },
    {
        key: 'atotz-bijles',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: {
            nl: 'Bijles',
        },
        content: {
            nl: () => import('./pageContent/atotz/atotz-bijles.md?raw'),
        },
    },
    {
        key: 'atotz-huiswerkbegeleiding',
        companyAbbr: ['AtotZ'],

        defaultLocale: 'nl',
        title: {
            nl: 'Huiswerkbegeleiding',
        },
        content: {
            nl: () => import('./pageContent/atotz/atotz-huiswerkbegeleiding.md?raw'),
        },
    },

    // Huiswerkschool Almere
    {
        key: 'hsa-about',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: {
            nl: 'Over ons',
        },
        content: {
            nl: () => import('./pageContent/hsa/hsa-about.md?raw'),
        },
    },
    {
        key: 'hsa-vestigingen',
        path: 'vestigingen',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: 'Vestigingen',
        titleMeta: 'Vestigingen van Huiswerkschool Almere',
        description: 'Vestigingen van Huiswerkschool Almere',
        content: () => import('./pageContent/hsa/vestigingen.md?raw'),
    },
    {
        key: 'hsa-tarieven',
        path: 'tarieven',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: 'Tarieven',
        titleMeta: 'Tarieven van Huiswerkschool Almere',
        description: 'Tarieven van Huiswerkschool Almere',
        content: () => import('./pageContent/hsa/tarieven.md?raw'),
    },
    {
        key: 'hsa-contact',
        path: 'hsa-contact',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: 'Contact',
        titleMeta: 'Contact',
        description: 'Contact Huiswerkschool Almere',
        content: () => import('./pageContent/hsa/contact.md?raw'),
    },
    {
        key: 'hsa-werken-bij',
        path: 'werken-bij',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: 'Werken bij + vacatures',
        titleMeta: 'Werken bij + vacatures Huiswerkschool Almere',
        description: 'Werken bij + vacatures Huiswerkschool Almere',
        content: () => import('./pageContent/hsa/vacatures.md?raw'),
    },
    {
        key: 'hsa-bijles',
        companyAbbr: ['HSA'],

        defaultLocale: 'nl',
        title: {
            nl: 'Bijles',
        },
        content: {
            nl: () => import('./pageContent/hsa/hsa-bijles.md?raw'),
        },
    },
    {
        key: 'cross-ref-studies',
        path: 'meer-van-athena',
        companyAbbr: ['ASTU'],

        defaultLocale: 'nl',
        title: 'Meer van Athena',
        content: {
            nl: () => import('./pageContent/cross-ref-studies.md?raw'),
        },
    },
    {
        key: 'cross-ref-school',
        path: 'meer-van-athena',
        companyAbbr: ['ASc'],

        defaultLocale: 'nl',
        title: 'Meer van Athena',
        content: {
            nl: () => import('./pageContent/cross-ref-school.md?raw'),
        },
    },
]);

export default staticPages;
